import { H1, H2 } from '../textos/Heading'
import Button from '../Button'
import Panel from '../paneles/Panel'
import PropTypes from 'prop-types'
import React from 'react'
import ResultadoCalificacion from '../paneles/ResultadoCalificacion'
import { secondsToTime } from '../../util/date'
import styled from 'styled-components'
import { colors } from '../../tokens'

const Container = styled.div`
  padding-top: 40px;
  text-align: center;
  .d-flex-center {
    justify-content: center;
  }
`

const MessageContainer = styled.div`
  width: 55%;
  margin: 40px auto;
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const Content = styled(Panel)`
  text-align: center;
  padding: 20px;
  margin: 10px;

  p {
    color: ${colors.base.gray};
  }

  .success {
    color: ${colors.base.green};
    font-size: 20px;
    font-weight: 700;
  }
`

const Botones = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-top: 20px;
    & > div {
      margin: 5px 0;
    }
  }
  @media screen and (max-width: 567px) {
    & > div {
      width: 100%;
    }
  }
`

function Results(props) {
  const {
    id,
    name,
    showResults,
    content,
    results,
    correct,
    total,
    onClick,
    redirectToContent,
    category,
    approved,
    certificateKey,
    timeToRetake,
    history,
  } = props
  const time = secondsToTime(timeToRetake * 60)
  const { h, m } = time
  return (
    <Container className="content">
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1">
          <ResultadoCalificacion
            respuestasCorrectas={correct}
            respuestasTotal={total}
            nota={`${correct}/${total}`}
          />
          <p>Tu calificación del {name}</p>
          <H1>¡Has llegado hasta el final!</H1>
          {category === 'final' && approved && (
            <MessageContainer>
              <H2 color={colors.base.green}>
                ¡Felicidades! has aprobado el examen de certificación.
              </H2>
              <Button
                onClick={() => history.push(`/certificates/${certificateKey}`)}
              >
                Ver mi certificado
              </Button>
            </MessageContainer>
          )}
          {category === 'final' && !approved && (
            <MessageContainer>
              <H2 color={colors.base.yello}>
                ¡Lo sentimos! no has aprobado el examen de certificación, no te
                desanimes puedes intentarlo nuevamente dentro de {h}:{m} horas
              </H2>
              <p>
                Te recomendamos revisar nuevamente las video clases del curso
              </p>
            </MessageContainer>
          )}
        </div>
      </div>

      <div className="pure-g d-flex-center">
        {results.map((result, key) => (
          <div key={key} className="pure-u-1 pure-u-md-1-3">
            <Content>
              <p>{result.name}</p>
              <div>
                <p className="success">
                  {result.correct} / {result.total}
                </p>
              </div>
            </Content>
          </div>
        ))}
      </div>

      <Botones>
        {showResults && (
          <div className="mx1">
            <Button
              onClick={() =>
                window.open(`https://simulador.alau.org/api/pdf/${id}`)
              }
              target="_blank"
              neutral
            >
              Ver Detalle
            </Button>
          </div>
        )}
        <div className="mx1">
          {content ? (
            <Button onClick={redirectToContent} primary>
              Continuar
            </Button>
          ) : (
            <Button onClick={onClick} primary>
              Salir
            </Button>
          )}
        </div>
      </Botones>
    </Container>
  )
}

Results.propTypes = {
  name: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  correct: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  category: PropTypes.string,
  approved: PropTypes.bool,
}

export default Results
